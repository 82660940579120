<template>
  <div class="h-screen relative">
    <content-holder
      v-if="canViewReports"
      :title="'Revenue Reconciliation'"
      class="absolute inset-0 p-6"
    >
      <div class="w-full flex flex-col sm:flex sm:flex-row sm:items-end">
        <div
          class="md:w-1/4 space-y-1 sm:space-y-4 sm:border-r border-gray-100 sm:pr-10"
        >
          <p class="text-gray-700 font-medium">Select payment dates</p>
          <div
            class="text-gray-500 pr-2 sm:pr-0 flex flex-row items-center space-x-1 sm:space-x-4 w-full dateRange sm:w-80 md:w-96 lg:w-full"
          >
            <div class="w-1/2 sm:w-full">
              <p class="text-sm">start date:</p>
              <input
                type="date"
                v-model="paymentSelectedDates.startDate"
                class="w-full bg-white border border-gray-500 px-2 rounded"
              />
              <!-- <date-picker
              :classList="['text-gray-700']"
              :newDate="paymentSelectedDates.startDate"
              :title="''"
              @updateDate="updateStartDate"
            ></date-picker> -->
            </div>
            <div class="w-1/2 sm:w-full">
              <p class="text-sm">end date:</p>
              <input
                type="date"
                v-model="paymentSelectedDates.endDate"
                class="w-full bg-white border border-gray-500 px-2 rounded"
              />
              <!-- <date-picker
              :classList="['text-gray-700']"
              :newDate="paymentSelectedDates.endDate"
              :title="''"
              @updateDate="updateEndDate"
            ></date-picker> -->
            </div>
          </div>
        </div>
        <div
          class="md:w-1/4 space-y-1 sm:space-y-4 sm:border-l mt-6 sm:mt-0 sm:pl-10 border-gray-100"
        >
          <p class="text-gray-700 font-medium">Select settlement dates</p>
          <div
            class="text-gray-500 pr-2 sm:pr-0 flex flex-row items-center space-x-1 sm:space-x-4 w-full dateRange sm:w-80 md:w-96 lg:w-full"
          >
            <div class="w-1/2 sm:w-full">
              <p class="text-sm">start date:</p>
              <input
                type="date"
                v-model="settlementSelectedDates.startDate"
                class="w-full bg-white border border-gray-500 px-2 rounded"
              />
              <!-- <date-picker
                :classList="['text-gray-700']"
                :newDate="settlementSelectedDates.startDate"
                :title="''"
                @updateDate="updateStartDate"
              ></date-picker> -->
            </div>
            <div class="w-1/2 sm:w-full">
              <p class="text-sm">end date:</p>
              <input
                type="date"
                v-model="settlementSelectedDates.endDate"
                class="w-full bg-white border border-gray-500 px-2 rounded"
              />
              <!-- <date-picker
                :classList="['text-gray-700']"
                :newDate="settlementSelectedDates.endDate"
                :title="''"
                @updateDate="updateEndDate"
              ></date-picker> -->
            </div>
          </div>
        </div>
        <button
          id="btnLoad"
          type="button"
          :disabled="reconcilingData"
          @click="runDataReconciliation"
          class="w-full sm:w-48 sm:ml-56 mt-10 sm:mt-0 px-4 py-2 font-semibold text-white text-md transition duration-500 ease-in-out transform rounded shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
        >
          <div v-if="!reconcilingData">Reconcile</div>
          <div v-else class="flex items-center space-x-4 py-1 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
          </div>
        </button>
      </div>
      <div class="flex flex-wrap overflow-hidden pt-8 md:space-x-4">
        <div
          class="w-full py-2 rounded-lg border border-gray-100 bg-yellow-200 dark:bg-gray-800 overflow-hidden sm:my-3 px-3 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/4"
        >
          <div class="flex items-center">
            <p
              class="text-lg sm:text-md text-gray-600 font-medium dark:text-gray-50"
            >
              Payment
            </p>
          </div>
          <div class="flex flex-col justify-start">
            <p
              class="text-gray-700 text-xl text-left dark:text-white font-bold"
            >
              {{ formatAmount(totalPayments[0]) }}
            </p>
          </div>
          <p>
            <span class="text-xs text-gray-500"
              >({{ formatNumber(totalPayments[1]) }}

              {{
                totalPayments[1] == 1 ? "transaction" : "transactions"
              }})</span
            >
          </p>
        </div>
        <div
          class="w-full py-2 my-4 rounded-lg border border-gray-100 bg-blue-200 dark:bg-gray-800 overflow-hidden sm:my-3 px-3 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/4"
        >
          <div class="flex items-center">
            <p
              class="text-lg sm:text-md text-gray-600 font-medium dark:text-gray-50"
            >
              Settlement
            </p>
          </div>
          <div class="flex flex-col justify-start">
            <p
              class="text-gray-700 text-xl text-left dark:text-white font-bold"
            >
              {{ formatAmount(totalSettlements[0]) }}
            </p>
          </div>
          <p>
            <span class="text-xs text-gray-500"
              >({{ formatNumber(totalSettlements[1]) }}

              {{
                totalSettlements[1] == 1 ? "transaction" : "transactions"
              }})</span
            >
          </p>
        </div>
        <div
          class="w-full py-2 rounded-lg border border-gray-100 bg-green-200 dark:bg-gray-800 overflow-hidden sm:my-3 px-3 sm:w-1/3 md:w-1/4 lg:w-1/4 xl:w-1/4"
        >
          <div class="flex items-center">
            <p
              class="text-lg sm:text-md text-gray-600 font-medium dark:text-gray-50"
            >
              Difference
            </p>
          </div>
          <div class="flex flex-col justify-start">
            <p
              class="text-gray-700 text-xl text-left dark:text-white font-bold"
            >
              {{
                showAmountDifference
                  ? formatAmount(calculateDifference())
                  : "0.00"
              }}
            </p>
          </div>
          <p>
            <span class="text-xs text-gray-500"
              >({{ formatNumber(calculateDifferenceInCount()) }}

              {{
                calculateDifferenceInCount() == 1
                  ? "transaction"
                  : "transactions"
              }})</span
            >
          </p>
        </div>
      </div>

      <div class="sm:flex sm:flex-row sm:items-center sm:space-x-6 mt-6 pb-20">
        <div
          class="flex flex-col sm:h-96 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 overflow-auto bg-white shadow rounded-sm"
        >
          <p class="w-full text-left text-xs py-2 text-gray-500 uppercase px-4">
            Payments
          </p>
          <table class="table p-2 w-full bg-white self-stretch rounded-lg">
            <thead>
              <tr class="text-xs font-bold">
                <th
                  class="border-b-2 text-left font-semibold py-2 px-4 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Amount
                </th>
                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Customer name
                </th>
                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Payment Date
                </th>

                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Settlement Date
                </th>
                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Payment Ref
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in payments_reconciled"
                :key="index"
                class="text-gray-700 text-xs text-left"
              >
                <td class="border-b-2 py-2 px-4 dark:border-dark-5">
                  <div class="w-28"></div>
                  {{ formatAmount(item.Amount) }}
                </td>

                <td class="border-b-2 p-2 dark:border-dark-5">
                  <div
                    :class="[
                      truncateName(item.customer_name) ? 'w-80' : 'w-auto',
                    ]"
                  >
                    {{ item.customer_name }}
                  </div>
                </td>
                <td class="border-b-2 p-2 dark:border-dark-5">
                  {{ item.payment_date_time }}
                </td>

                <td class="border-b-2 p-2 dark:border-dark-5">
                  {{ item.settlement_date }}
                </td>
                <td class="border-b-2 p-2 dark:border-dark-5">
                  {{ item.payment_ref_no }}
                </td>
              </tr>
            </tbody>
          </table>
          <p class="px-4 py-2 text-xs font-medium text-gray-600">
            Total : {{ formatAmount(totalReconciledPayments) }}
          </p>
        </div>
        <div
          class="sm:h-96 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mt-6 sm:mt-0 overflow-auto bg-white shadow rounded-sm"
        >
          <p class="w-full text-left text-xs py-2 text-gray-500 uppercase px-4">
            Settlements
          </p>
          <table class="table p-2 w-full bg-white rounded-lg">
            <thead>
              <tr class="text-xs font-bold">
                <th
                  class="border-b-2 text-left font-semibold py-2 px-4 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Amount
                </th>

                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Customer name
                </th>
                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Payment Date
                </th>

                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Settlement Date
                </th>
                <th
                  class="border-b-2 text-left font-semibold p-2 dark:border-dark-5 whitespace-nowrap text-gray-600"
                >
                  Payment Ref
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in settlements_reconciled"
                :key="index"
                class="text-gray-700 text-xs text-left"
              >
                <td class="border-b-2 py-2 px-4 dark:border-dark-5">
                  <div class="w-28">
                    {{ formatAmount(item.Amount) }}
                  </div>
                </td>

                <td class="border-b-2 p-2 dark:border-dark-5">
                  <div
                    :class="[
                      truncateName(item.customer_name) ? 'w-80' : 'w-auto',
                    ]"
                  >
                    {{ item.customer_name }}
                  </div>
                </td>
                <td class="border-b-2 p-2 dark:border-dark-5">
                  {{ item.payment_date_time }}
                </td>

                <td class="border-b-2 p-2 dark:border-dark-5">
                  {{ item.settlement_date }}
                </td>
                <td class="border-b-2 p-2 dark:border-dark-5">
                  {{ item.payment_ref_no }}
                </td>
              </tr>
            </tbody>
          </table>
          <p class="px-4 py-2 text-xs font-medium text-gray-600">
            Total : {{ formatAmount(totalReconciledSettlements) }}
          </p>
        </div>
      </div>
    </content-holder>
    <content-holder
      v-else
      :title="'Revenue Reconciliation'"
      class="absolute inset-0 p-6"
    >
      <unauthorized-access></unauthorized-access>
    </content-holder>

    <!-- <teleport to=".report-modal">
            <report-modal :date="formatDates()" :key="reportName" :reportName="reportName" :reportType="reportType"
                @close-modal="generateReportModal = false" v-show="generateReportModal" class="absolute inset-0 z-50 ">
            </report-modal>
        </teleport> -->
  </div>
</template>

<script>
// import DatePicker from "@/components/DatePicker2.vue";
import ContentHolder from "@/components/ContentHolder.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import { views } from "@/helpers/login_details.js";
import numeral from "numeral";
import moment from "moment";
export default {
  name: "Reports",
  components: {
    ContentHolder,
    UnauthorizedAccess,
    // DatePicker,
  },
  data: () => ({
    paymentSelectedDates: {
      startDate: moment().format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    },
    settlementSelectedDates: {
      startDate: moment().format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    },
    showAmountDifference: false,
    payments: {},
    settlements: {},
    payments_reconciled: {},
    settlements_reconciled: {},
    reconcilingData: false,
    formattedDate: {},
    reportType: "",
  }),
  computed: {
    totalPayments() {
      let total = 0;
      let count = 0;

      if (this.payments.result)
        this.payments.result.forEach((item) => {
          count += 1;
          total += parseFloat(item.Amount);
        });

      return [total, count];
    },
    totalSettlements() {
      let total = 0;
      let count = 0;

      if (this.settlements.result)
        this.settlements.result.forEach((item) => {
          count += 1;
          total += parseFloat(item.Amount);
        });

      return [total, count];
    },
    totalReconciledPayments() {
      let total = 0;
      if (this.payments_reconciled.length > 0)
        this.payments_reconciled.forEach((item) => {
          total += parseFloat(item.Amount);
        });

      return total;
    },
    totalReconciledSettlements() {
      let total = 0;

      if (this.settlements_reconciled.length > 0)
        this.settlements_reconciled.forEach((item) => {
          total += parseFloat(item.Amount);
        });

      return total;
    },
  },
  methods: {
    truncateName(name) {
      if (name != null) {
        if (name.length > 20) return true;
      }

      return false;
    },
    calculateDifference() {
      if (this.totalPayments[0] > this.totalSettlements[0])
        return this.totalPayments[0] - this.totalSettlements[0];
      return this.totalSettlements[0] - this.totalPayments[0];
    },
    calculateDifferenceInCount() {
      if (this.totalPayments[1] > this.totalSettlements[1])
        return this.totalPayments[1] - this.totalSettlements[1];
      return this.totalSettlements[1] - this.totalPayments[1];
    },
    formatAmount(amt) {
      return `₦ ${numeral(amt).format("0,0.00")}`;
    },
    formatNumber(amt) {
      return `${numeral(amt).format("0,0")}`;
    },
    updateStartDate(newDate, type = "payments") {
      if (type === "payments") this.paymentSelectedDates.startDate = newDate;
      else this.settlementSelectedDates.startDate = newDate;
    },
    updateEndDate(newDate, type = "payment") {
      if (type === "payments") this.paymentSelectedDates.endDate = newDate;
      else this.settlementSelectedDates.endDate = newDate;

      this.dateUpdated = true;
    },
    formatDates(dt) {
      return {
        startDate: moment(dt.startDate).format("YYYY-MM-DD"),
        endDate: moment(dt.endDate).format("YYYY-MM-DD"),
      };
    },
    getPaymentDifference() {
      this.payments_reconciled = this.payments.result.filter(
        (item) =>
          !(
            moment(item.settlement_date, "DD-MMM-YY hh:mm:ss").format(
              "YYYY-MM-DD"
            ) >=
              moment(this.settlementSelectedDates.startDate).format(
                "YYYY-MM-DD"
              ) &&
            moment(item.settlement_date, "DD-MMM-YY hh:mm:ss").format(
              "YYYY-MM-DD"
            ) <=
              moment(this.settlementSelectedDates.endDate).format("YYYY-MM-DD")
          )
      );
    },
    getSettlementDifference() {
      this.settlements_reconciled = this.settlements.result.filter(
        (item) =>
          !(
            moment(item.payment_date_time, "DD-MMM-YY hh:mm:ss").format(
              "YYYY-MM-DD"
            ) >=
              moment(this.paymentSelectedDates.startDate).format(
                "YYYY-MM-DD"
              ) &&
            moment(item.payment_date_time, "DD-MMM-YY hh:mm:ss").format(
              "YYYY-MM-DD"
            ) <= moment(this.paymentSelectedDates.endDate).format("YYYY-MM-DD")
          )
      );
    },
    async runDataReconciliation() {
      try {
        this.reconcilingData = true;
        let filter = {
          limit: 3000,
          page: 1,
          channel: "All",
          reportType: "payment",
          ...this.formatDates(this.paymentSelectedDates),
        };
        this.payments = await this.$store.dispatch(
          "getReconciliationData",
          filter
        );
        filter = {
          limit: 3000,
          page: 1,
          channel: "All",
          reportType: "settlement",
          ...this.formatDates(this.settlementSelectedDates),
        };
        this.settlements = await this.$store.dispatch(
          "getReconciliationData",
          filter
        );

        this.getPaymentDifference();
        this.getSettlementDifference();
        this.showAmountDifference = true;
      } catch (error) {
        console.log(error);
      } finally {
        this.reconcilingData = false;
      }
    },
  },
  async created() {
    try {
      if (views.includes("Reports")) {
        this.canViewReports = true;
      } else {
        this.canViewReports = false;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
